import * as _has2 from "has";
var _has = "default" in _has2 ? _has2.default : _has2;
import _core from "./core.json";
import _process from "process";
var exports = {};
var process = _process;
var has = _has;
function specifierIncluded(current, specifier) {
  var nodeParts = current.split(".");
  var parts = specifier.split(" ");
  var op = parts.length > 1 ? parts[0] : "=";
  var versionParts = (parts.length > 1 ? parts[1] : parts[0]).split(".");
  for (var i = 0; i < 3; ++i) {
    var cur = parseInt(nodeParts[i] || 0, 10);
    var ver = parseInt(versionParts[i] || 0, 10);
    if (cur === ver) {
      continue; // eslint-disable-line no-restricted-syntax, no-continue
    }

    if (op === "<") {
      return cur < ver;
    }
    if (op === ">=") {
      return cur >= ver;
    }
    return false;
  }
  return op === ">=";
}
function matchesRange(current, range) {
  var specifiers = range.split(/ ?&& ?/);
  if (specifiers.length === 0) {
    return false;
  }
  for (var i = 0; i < specifiers.length; ++i) {
    if (!specifierIncluded(current, specifiers[i])) {
      return false;
    }
  }
  return true;
}
function versionIncluded(nodeVersion, specifierValue) {
  if (typeof specifierValue === "boolean") {
    return specifierValue;
  }
  var current = typeof nodeVersion === "undefined" ? process.versions && process.versions.node : nodeVersion;
  if (typeof current !== "string") {
    throw new TypeError(typeof nodeVersion === "undefined" ? "Unable to determine current node version" : "If provided, a valid node version is required");
  }
  if (specifierValue && typeof specifierValue === "object") {
    for (var i = 0; i < specifierValue.length; ++i) {
      if (matchesRange(current, specifierValue[i])) {
        return true;
      }
    }
    return false;
  }
  return matchesRange(current, specifierValue);
}
var data = _core;
exports = function isCore(x, nodeVersion) {
  return has(data, x) && versionIncluded(nodeVersion, data[x]);
};
export default exports;